.trusted-by-section {
  text-align: center;
  background-color: white;
}

.trusted-by-section h2 {
  font-size: 2em;
  margin-bottom: 30px;
  color: #333;
}

.logo-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px; /* Espacio entre logos para pantallas grandes */
  overflow: hidden;
}

.logo-slider .slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0;
  flex: 0 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.logo-slider .slide:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.9);
}

.client-logo {
  max-width: 210px;
  max-height: 140px;
  width: auto;
  height: auto;
  object-fit: contain;
  filter: none;
  margin: 0;
}

/* Media Query para Pantallas Pequeñas */
@media (max-width: 768px) {
  .logo-slider {
    gap: 5px; /* Reduce el espacio entre logos a la mitad en pantallas pequeñas */
  }
}
