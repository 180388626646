/* Ajustes generales */
.features {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  position: relative;
}

.feature-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  backdrop-filter: blur(15px);
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  transform: translateY(30px);
  will-change: transform, opacity;
  position: relative;
}

.feature-container:nth-child(even) {
  flex-direction: row-reverse;
}

.feature-container.in-view {
  opacity: 1;
  transform: translateY(0);
  animation: fadeInUp 1.2s ease forwards;
}

.feature-text {
  flex: 1;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  margin-bottom: 20px;
}

.feature-text h2 {
  margin-bottom: 1rem;
  font-size: 2.2rem;
  font-weight: bold;
  color: #333;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  -webkit-background-clip: text;
  color: transparent;
}

.feature-text p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #555;
}

.feature-text ul {
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
  text-align: left;
}

.feature-text ul li {
  margin-bottom: 0.5rem;
  color: #666;
  position: relative;
  padding-left: 30px;
  font-size: 1.1rem;
}

.feature-text ul li::before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230099cc" width="18px" height="18px"><path d="M9 16.17l-3.59-3.59L4 13l5 5L20 7l-1.41-1.41L9 16.17z"/></svg>');
  position: absolute;
  left: 0;
  top: 0;
}

.feature-text .cta-button {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 10px 20px;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.feature-text .cta-button:hover {
  background: linear-gradient(to right, #0099cc, #66b3ff);
  transform: translateY(-3px);
}

.feature-image-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.feature-image {
  text-align: center;
  background-color: transparent;
  box-shadow: none;
}

.feature-image img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  display: block;
}

/* Media Query para Pantallas Pequeñas */
@media (max-width: 768px) {
  .features {
    padding: 30px;
  }

  .feature-container {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }

  .feature-container:nth-child(even) {
    flex-direction: column;
  }

  .feature-text {
    width: 100%;
    padding: 0;
    order: 1;
    text-align: center;
    margin-bottom: 20px;
  }

  .feature-text h2 {
    font-size: 1.8rem;
    padding: 0;
  }

  .feature-text p {
    font-size: 1rem;
  }

  .feature-text ul {
    text-align: left;
    padding-left: 20px;
  }

  .feature-text ul li {
    font-size: 1rem;
    padding-left: 25px;
  }

  .feature-image-wrapper {
    width: 100%;
    margin-top: 20px;
    order: 2;
  }

  .feature-image img {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

