.footer-wrapper {
  background: linear-gradient(to right, #66b3ff, #0099cc);
  padding: 40px 0;
}

.footer {
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: transparent;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-column {
  flex: 1;
  margin: 0 10px;
}

.footer-column h4 {
  margin-bottom: 15px;
  font-size: 1.4rem;
  font-weight: bold;
  background: linear-gradient(to right, #fff, #ccc);
  -webkit-background-clip: text;
  color: transparent;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-column ul li a:hover {
  color: #ff6600;
  transform: translateX(5px);
}

.footer-logo {
  width: 150px;
  margin-bottom: 15px;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.2));
}

.footer-slogan {
  font-size: 1rem;
  margin-top: 10px;
  color: #f0f0f0;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
  color: #ddd;
}

.linkedin-icon {
  width: 30px;
  height: auto;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.linkedin-icon:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
    text-align: center;
  }

  .footer-column {
    margin-bottom: 20px;
  }

  .footer-slogan {
    text-align: center; /* Centra el texto bajo el logo en pantallas pequeñas */
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom p {
    margin-bottom: 10px;
  }
}
