.get-started-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  backdrop-filter: blur(15px);
  margin-bottom: 10vh;
}

.get-started-form h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.form-subtitle {
  font-size: 1rem;
  font-style: italic;
  text-align: center;
  margin-bottom: 20px;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(0, 153, 204, 0.4);
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #0099cc;
  outline: none;
}

.submit-button {
  background: linear-gradient(to right, #66b3ff, #0099cc);
  color: #fff;
  padding: 15px 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background: linear-gradient(to right, #0099cc, #66b3ff);
  transform: translateY(-3px);
}

.form-disclaimer {
  font-size: 0.8rem;
  color: #666;
  text-align: center;
  margin-top: 20px;
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  backdrop-filter: blur(15px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 10vh;
  text-align: center;
}

.form-success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.success-icon {
  font-size: 4rem;
  color: #66b3ff;
  margin-bottom: 20px;
}

.submit-button {
  background: linear-gradient(to right, #66b3ff, #0099cc);
  color: #fff;
  padding: 15px 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}
