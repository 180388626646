.about-us {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  margin-top: 10vh;
}

.about-us-intro,
.about-us-trajectory,
.about-us-krino,
.about-us-investors,
.about-us-trusted-by {
  margin-bottom: 40px;
}

h1, h2 {
  font-size: 2.5rem; /* Tamaño de fuente mejorado */
  margin-bottom: 20px;
  color: #333;
  font-family: 'Montserrat', sans-serif; /* Mantener fuente atractiva */
}
.success-stories h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
}

p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  font-family: 'Roboto', sans-serif; /* Fuente secundaria para cuerpo de texto */
}

.about-us-founders {
  margin-bottom: 40px;
}

.founders-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Espacio entre las columnas */
  flex-wrap: wrap; /* Asegura que sea responsivo */
}

.cofounders-image {
  flex: 1;
  max-width: 40%; /* Tamaño de la imagen ajustado a un tamaño más normal */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.founders-content {
  flex: 1;
  max-width: 50%;
}

@media (max-width: 768px) {
  .founders-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cofounders-image,
  .founders-content {
    max-width: 100%;
  }
}
