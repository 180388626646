.track-record {
  padding: 50px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 15px;
}

.track-record-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.track-record-header h2 {
  margin-right: 20px;
  font-size: 2.5em;
  font-weight: bold;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  -webkit-background-clip: text;
  color: transparent;
}

.company-logo {
  width: 200px; /* Doble del tamaño actual */
  height: auto;
  display: inline-block; /* Asegura que sea visible en escritorio */
}

.track-record-subtitle {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #555;
  text-align: center; /* Centra el texto en ambos casos */
}

.metrics {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center; /* Centra el texto de las métricas */
}

.metric {
  flex: 1;
  max-width: 30%;
  text-align: center;
  margin: 20px 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  backdrop-filter: blur(10px);
}

.metric:hover {
  transform: translateY(-10px);
  background-color: rgba(255, 255, 255, 1);
}

.metric-icon {
  font-size: 50px;
  color: #0099cc;
  margin-bottom: 10px;
}

.metric h3 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 5px;
}

.metric p {
  font-size: 1rem;
  color: #666;
}

/* Media Query para Pantallas Pequeñas */
@media (max-width: 768px) {
  .metrics {
    flex-direction: column;
  }

  .metric {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .company-logo {
    display: none; /* Oculta el logo en pantallas pequeñas */
  }
}
