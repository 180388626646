.privacy-policy {
  margin-top: 5vh;
  max-width: 800px;
  margin: 60px auto; /* Más espacio al inicio */
  padding: 40px 20px;
  line-height: 1.8; /* Incremento del interlineado para mayor legibilidad */
  color: #333;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff; /* Fondo blanco para un aspecto de documento */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Sombra suave para un efecto de flotación */
  border-radius: 8px; /* Bordes sutilmente redondeados */
}

h1, h2 {
  color: #333; /* Color de texto estándar */
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.6em; /* Tamaño de fuente ajustado para subtítulos */
}

p {
  margin-bottom: 20px;
  color: #555;
  font-size: 1em;
  text-align: justify; /* Justificado para darle un aspecto más formal */
}
