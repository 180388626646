.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 65vh; /* Restaurar altura original en escritorio */
  background: white;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 40px; /* Restaurar padding original */
}

.hero-content {
  max-width: 80%;
  text-align: center;
  margin-bottom: 20px;
}

.hero-content h1 {
  font-size: 3em; /* Restaurar tamaño del título para escritorio */
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #333;
}

.hero-content h4 {
  font-size: 1em;
  margin-bottom: 30px;
  color: #555;
}

.cta-button {
  display: inline-block;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  margin: 20px auto;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
}

.cta-button:hover {
  background: linear-gradient(to right, #0099cc, #66b3ff);
}

.outline-button {
  background-color: transparent;
  color: #333;
  border: 2px solid #0099cc;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.outline-button:hover {
  background-color: #0099cc;
  color: #fff;
}

/* Estilos para los elementos decorativos con centrado vertical y colores celestes */
.hero-section::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  clip-path: path("M17.833 35.056C15.337 26.509 9.379 21.045 0.740997 17.964C10.633 16.295 15.641 9.936 17.833 0.873001C21.825 10.05 27.402 15.993 34.925 17.964C26.459 21.374 20.738 27.052 17.833 35.056Z");
}

.hero-section::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  width: 30px;
  height: 31px;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  clip-path: path("M12.6591 0.630493C11.0871 7.12849 7.49706 11.6875 0.40506 12.8845C6.59706 15.0935 10.8691 19.0105 12.6591 25.1385C14.7411 19.4005 18.8431 15.3295 24.9131 12.8845C19.5201 11.4705 15.5211 7.21049 12.6591 0.630493Z");
}

/* Media Queries */
@media (min-width: 769px) {
  .hero-section {
    flex-direction: row;
    text-align: center;
  }

  .hero-content {
    margin-bottom: 0;
    text-align: center;
    max-width: 70%;
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    padding: 20px;
    margin-top: 10vh;
    height: auto; /* Asegura que ocupe toda la altura disponible en móviles */
  }

  .hero-content h1 {
    font-size: 2em; /* Reduce el tamaño del título solo en pantallas pequeñas */
  }

  .hero-content {
    max-width: 90%;
  }
}
