/* Estilos generales del header ya existentes */
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1000;
  padding: 15px 40px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.logo img {
  height: 50px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
  align-items: center;
}

.nav-links li a {
  text-decoration: none;
  color: #0099cc;
  font-weight: bold;
  font-size: 1.1em;
  transition: color 0.3s ease, transform 0.3s ease;
  padding: 8px 12px;
}

.nav-links li a:hover {
  color: #66b3ff;
  transform: scale(1.1);
}

.right-section {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 1001;
  flex-direction: row;
  width: auto; /* Ajuste de ancho automático */
}

.demo-button {
  display: block;
}

.demo-button a {
  background: linear-gradient(to right, #66b3ff, #0099cc);
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.demo-button a:hover {
  background: linear-gradient(to right, #0099cc, #66b3ff);
  transform: translateY(-3px);
}

.language-selector {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1002;
  margin-left: 10px; /* Reduce el margen izquierdo para móviles */
}

.flag-icon {
  height: 16px; /* Reduce el tamaño de las banderas */
  max-height: 100%;
  margin-left: 5px; /* Reduce el margen entre banderas */
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 40px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1003;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li img {
  height: 15px;
  margin-right: 10px;
}

.mobile-menu-button {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    flex-direction: column;
    gap: 15px;
    padding: 20px;
  }

  .nav-links.open {
    display: flex;
  }

  .demo-button {
    display: none;
  }

  .right-section {
    width: 100%;
    justify-content: space-between; /* Divide el espacio entre el menú y el selector de idioma */
    padding: 0 10px; /* Añade padding para separar los elementos */
  }

  .language-selector {
    display: flex;
    align-items: center;
    width: 50%; /* Toma la mitad del espacio disponible */
    margin-top: 0;
  }

  .mobile-menu-button {
    display: block;
    width: 100%; /* Toma la mitad del espacio disponible */
    text-align: right; /* Alinea el botón de menú a la izquierda */
  }
}
