.success-stories {
  padding: 4rem 2rem;
  background-color: #f8f9fa;
  max-width: 1200px;
  margin: 0 auto;
}

.success-stories h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  -webkit-background-clip: text;
  color: transparent;
}

.case-study {
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-stories .case-study {
  padding: 1rem;
}

.case-study h3 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #333;
}

.narrative {
  margin: 2rem 0;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.narrative p {
  font-size: 1rem;
}

.growth-chart {
  margin: 3rem 0;
}

.growth-highlights {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.highlight-card {
  text-align: center;
  padding: 1.5rem;
  background: linear-gradient(to right, #66b3ff, #0099cc);
  border-radius: 12px;
  color: white;
  flex: 1;
  margin: 0 1rem;
}

.highlight-value {
  font-size: 2rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.highlight-label {
  font-size: 1rem;
}

.key-findings {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
}

.titleSucess {
  background: #f8f9fa;
  color: #333;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
}

.key-findings h4 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.key-findings ul {
  list-style: none;
  padding: 0;
}

.key-findings li {
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
  color: #555;
}

.key-findings li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #0099cc;
}

.cta {
  background: white;
  color: #333;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  margin-top: 3rem;
}

.cta h4 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.cta p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: white;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .success-stories {
    padding: 2rem 1rem;
  }

  .success-stories h2 {
    font-size: 2rem;
  }

  .case-study {
    padding: 1.5rem;
  }

  .growth-highlights {
    flex-direction: column;
  }

  .highlight-card {
    margin: 1rem 0;
  }

  .cta {
    padding: 1.5rem;
  }
}

@media (max-width: 600px) {
  .success-stories .case-study {
    padding: 0.5rem;
  }

  .success-stories h2 {
    font-size: 1.8rem;
  }

  .success-stories h3 {
    font-size: 1.5rem;
  }

  .success-stories .narrative p {
    font-size: 0.9rem;
  }

  .success-stories .key-findings li {
    font-size: 0.9rem;
  }
}

/* Estilos específicos para el gráfico de comparación */
.comparison-chart-container {
  width: 100%;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .comparison-chart-container {
    margin-bottom: 1rem;
  }
}

